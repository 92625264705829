import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CallToAction from "../../components/CallToAction"
import {
  SectionPage,
  MainPanel,
  LeftPanel,
  RightPanel,
  MarkdownContent,
  Container,
  SectionTitle,
} from "../../components/Section"
import { SidebarSticky } from "../../components/Sidebar"
import DesignPlan from "../../components/DesignPlan"
import AccreditedBusiness from "../../components/AccreditedBusiness"
import scrollToRef from "../../components/scrollToRef"
import QuotePopup from "../../components/QuotePopup"
import FinancingHero from "../../sections/BuyingGuide/FinancingHero"
import FinanceAvailable from "../../sections/BuyingGuide/FinanceAvailable"
// import FinanceFormSection from "../../sections/BuyingGuide/FinanceFormSection"
import TopSellingProducts from "../../sections/BuyingGuide/TopSellingProducts"
import BenefitCarousel from "../../components/BenefitCarousel"

const FinancingProcessPage = ({ data, location }) => {
  const [isQuoteVisible, setQuoteVisible] = useState(false)
  const pageContent = data.allContentfulFinancingPage.edges[0].node
  const productData = data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  const applyRef = React.createRef()
  const applyNav = () => {
    scrollToRef(applyRef)
  }
  const handleModalClose = () => {
    setQuoteVisible(false)
  }
  const handleModalOpen = () => {
    setQuoteVisible(true)
  }
  return (
    <Layout location={location}>
      <SEO
        title={pageContent.metaTitle}
        description={pageContent.metaDescription.metaDescription}
      />
      <FinancingHero
        data={pageContent.heroSection}
        subTitle={pageContent.heroSubTitle}
        applyCallBack={applyNav}
        location={location}
      />
      <FinanceAvailable
        data={pageContent.availableOption}
        modalOpen={handleModalOpen}
      />
      <SectionPage ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bg="#f4fbff">
        <Container>
          <SectionTitle textAlign="center" mb="20px">
            {pageContent.heroSubTitle}
          </SectionTitle>
          <BenefitCarousel data={pageContent.heroSection.features} />
        </Container>
      </SectionPage>
      {/* <div ref={applyRef} />
      <FinanceFormSection data={pageContent.formSection} location={location} />
      <div ref={applyRef} /> */}      
      <SectionPage ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px">
        <Container>
          <MainPanel>
            <LeftPanel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: pageContent.contentSection.childMarkdownRemark.html,
                }}
              />
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="isSticky">
                <DesignPlan />
                <AccreditedBusiness />
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </Container>
      </SectionPage>
      <TopSellingProducts
        data={pageContent.cardSection}
        products={productData}
        location={location}
      />
      <QuotePopup
        isVisible={isQuoteVisible}
        onClose={handleModalClose}
        location={location}
        formName="Financing Get Quote Form"
      />
      <CallToAction data={pageContent.ctaSection} />
    </Layout>
  )
}

export default FinancingProcessPage

export const pageQuery = graphql`
  query FinancingProcessPageQuery {
    allContentfulFinancingPage(filter: { title: { eq: "Financing Process" } }) {
      edges {
        node {
          title
          metaTitle
          metaDescription {
            metaDescription
          }
          heroSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              title
              description
              icon
            }
          }
          heroSubTitle
          applySection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              content
            }
          }
          availableOption {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            features {
              image
              description
              options
            }
          }
          formSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ctaSection {
            title
            description {
              description
            }
          }
          contentSection {
            childMarkdownRemark {
              html
            }
          }
          cardSection {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    allContentfulProduct(sort: { fields: numberOfSale }, limit: 20) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
